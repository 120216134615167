section.gray {
  background: $gray-700;
  color: white;
  font-weight: $font-weight-bold;
  position: relative;

  h1,
  h2 {
    color: $yellow;
  }

  ul {
    @include font-size(22px);
  }

  article {
    position: relative;
    z-index: 5;
  }

  footer {
    margin-top: $headings-margin-bottom * 1.5;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: flex-start;

      p {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }

  &::before {
    @include pseudo-content;
    background: $yellow;
    z-index: -1;
    top: -25px;
    bottom: -25px;
    clip-path: polygon(0 0, 100% 25px, 100% 100%, 0 calc(100% - 25px));
  }

  &::after {
    @include pseudo-content;
    background: rgba(white, 0.05);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
}

section#info {
  background: white;

  img {
    border-bottom: 10px solid $green;
    transition: all 0.3s $easing-custom;
    opacity: 0;
    @include media-breakpoint-up(lg) {
      transform: translateY(45px) translateX(-30px);
      transition-delay: 0.2s;
    }
  }

  article {
    background-color: white;
    transition: all 0.3s $easing-custom;
    opacity: 0;
    @include media-breakpoint-up(lg) {
      transform: translateX(-30px);
    }
  }

  .swiper-arrow-prev,
  .swiper-arrow-next {
    bottom: -45px;

    svg {
      filter: none;
    }
  }

  .swiper-arrow-next {
    left: 60px;
  }

  .swiper-container {
    overflow: visible;
    height: 400px;
  }

  // Animation
  .swiper-slide.transitionEnd {
    article {
      opacity: 1;
      transform: none;
    }
    img {
      opacity: 1;
      @include media-breakpoint-up(lg) {
        transform: translateY(45px);
      }
    }
  }
}
