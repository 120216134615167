#topbar nav {
  font-weight: $font-weight-bold;
  color: $gray-700;
  line-height: 1.2;
  @include font-size(18px);

  a {
    color: inherit;
    margin: 0 0.65rem;
    &:hover  {
      text-decoration: none;
      color: $primary;
    }
  }

  // Mobile
  @include max-breakpoint {
    position: fixed;
    top: 60px;
    left: 0;
    right:0;
    //width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background: linear-gradient(135deg, rgba($darkblue, 0.9), rgba($green, 0.9));
    backdrop-filter: blur(10px);
    z-index: 1234;
    transition: all 0.3s $easing-custom;
    padding-bottom: 120px;

    a {
      color: white;
      font-weight: $font-weight-bold;
      margin: 0.45rem;
      opacity: 0;
      transform: translateY(30px);
      transition: all 0.25s $easing-custom;

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
          transition-delay: 0.08s + $i * 0.1s;
        }
      }
    }
    
    // Menu button clicked
    &.active {
      opacity: 1;
      visibility: visible;

      a {
        opacity: 1;
        transform: none;
      }
    }

  }
}
