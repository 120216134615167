#topbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 60px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 12345;
  background: rgba(white, 0.6);
  backdrop-filter: blur(40px);
  .logo {
    width: 120px;
    margin-left: auto;
  }

  @include breakpoint {
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    height: auto;

    .logo {
      width: 190px;
      margin-left: 0;
    }

    .hamburger {
      display: none;
    }
  }
}

#header {
  position: relative;
  background: $gray-200;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .single {
    height: 400px;
    @include breakpoint {
      min-height: 800px;
    }
  }

  .container {
    position: relative;
  }

  .caption {
    position: absolute;
    background: rgba($yellow, 0.9);
    backdrop-filter: blur(3px);
    padding: 0.8rem 2.65rem;
    color: white;
    bottom: 0;
    right: 10px;
    @include font-size(22px);
    text-transform: uppercase;
    line-height: 1.3;
    clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(20%) translateX(-20px);
    transition: all 0.6s ease;

    @include breakpoint {
      font-size: 35px;
      padding: 1.75rem 5rem;
      right: $grid-gutter-width;
    }
  }

  .swiper-slide, .single {
    //height: 60vh;
    height: 600px;
    min-height: 400px;
  }

  .swiper-arrow-next {
    @include max-breakpoint {
      left: 70px;
    }
  }

  .swiper-arrow-next, .swiper-arrow-prev {
    @include max-breakpoint {
      bottom: 10px;
    }
  }

  // Animation
  .swiper-slide.transitionEnd .caption {
    transform: none;
    opacity: 1;
  }
}
