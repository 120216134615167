article.readmore {
  max-height: 10.5rem;
}

/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-300italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'),
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'),
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-700italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'),
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

body {
  @include antialiased;
}

//* Responsive font size
html {
  font-size: 0.85rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 0.92rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

h3 {
  color: $blue;
}

p {
  margin-bottom: 1.618rem;
}

article {
  &.max-width {
    max-width: 65rem;
  }
}


