footer#footer {
  border-top: 5px solid $gray-300;
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 4;
  color: $gray-800;
  position: relative;

  a {
    color: inherit;
  }
  nav {
    a {
      display: block;
      font-weight: $font-weight-bold;
    }
  }

  .wrapper > * {
    margin-bottom: 1rem;
  }

  .container-logo {
    max-width: 190px;
  }

}
