// Font Face Mixin
// Order of the includes matters, and it is: normal, bold, italic, bold+italic.
// sass-lint:disable-all
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix')          format('embedded-opentype'),
           font-url('#{$file-path}.woff')                format('woff'),
           font-url('#{$file-path}.ttf')                 format('truetype'),
           font-url('#{$file-path}.svg##{$font-family}') format('svg');
    } @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix')               format('embedded-opentype'),
           url('#{$file-path}.woff')                     format('woff'),
           url('#{$file-path}.ttf')                      format('truetype'),
           url('#{$file-path}.svg##{$font-family}')      format('svg');
    }
  }
}
// sass-lint:enable-all


@mixin breakpoint {
  @media (min-width: #{$nav-breakpoint}) {
    @content;
  }
}

@mixin max-breakpoint {
  @media (max-width: #{$nav-breakpoint - 1}) {
    @content;
  }
}

@mixin respond($min: -1, $max: -1, $media: "all") {
  $query: "only " + $media;
  @if $min != -1 and $max != -1 {
    $query: "only " + $media + " and (min-width: " + $min + ") and (max-width: " + ($max - 1) + ")";
  } @else if $min == -1 and $max != -1 {
    $query: "only " + $media + " and (max-width: " + ($max - 1) + ")";
  } @else if $min != -1 and $max == -1 {
    $query: "only " + $media + " and (min-width: " + $min + ")";
  }
  @media #{$query} {
    @content;
  }
}

$responsive-padding: (
  null: 25px 25px,
  map-get($grid-breakpoints, sm): 30px 30px,
  map-get($grid-breakpoints, md): 40px 40px,
  map-get($grid-breakpoints, lg): 50px 50px,
);

@mixin responsive-size($fs-map) {
  @each $fs-breakpoint, $fs-responsive-padding in $fs-map {
    @if $fs-breakpoint == null {
      padding: $fs-responsive-padding;
    } @else {
      @media screen and (min-width: $fs-breakpoint) {
        padding: $fs-responsive-padding;
      }
    }
  }
}

@mixin antialiased {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
}

@mixin pseudo-content {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #000;
}

@mixin font-awesome-as-content {
  font-family: 'FontAwesome', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
}

@mixin heading($font-size) {
  font-family: $headings-font-family;
  line-height: $headings-line-height;
  font-weight: $headings-font-weight;
  color: $headings-color;
  text-transform: uppercase;
  font-size: $font-size;
}

@mixin monospace($font-size) {
  font-family: $font-family-monospace;
  font-weight: 400;
  color: $headings-color;
  font-size: $font-size;
  // letter-spacing: 0.3px;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left topleft, $direction) {
    @error "Direction must be either top, right, bottom or left.";
  }
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  $perpendicular-borders: $size solid transparent;
  @if $direction == top {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
    border-bottom: $size solid $color;
  } @else if $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
    border-top: $size solid $color;
  } @else if $direction == right {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
    border-left: $size solid $color;
  } @else if $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
    border-right: $size solid $color;
  } @else if $direction == topleft {
    border-right: $perpendicular-borders;
    border-top: $size solid $color;
  }
}

@mixin force-hardware-acceleration {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

%clearfix {
  *zoom: 1;
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

// Usage: @include font-size(14px)
// Output: font-size: 0.8rem;
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

// Renders an ellipsis (“…”) to represent the clipped text
@mixin truncateText($overflow: ellipsis){
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow; // values are: clip, ellipsis, or a string
}