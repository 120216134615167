.swiper-arrow-prev,
.swiper-arrow-next {
  position: absolute;
  //top: 50%;
  width: 40px;
  bottom: 40px;
  z-index: 1337;
  cursor: pointer;

  &.swiper-button-disabled {
    opacity: 0.35;
    cursor:not-allowed;
  }

  svg {
    filter: drop-shadow(0 1px 10px black);
  }

  svg line,
  svg polyline {
    fill: none;
    stroke: $primary;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
  }
}

.swiper-navigation {
  position: relative;
}

.swiper-arrow-next {
  left: 100px;
}
