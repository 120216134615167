.btn {
  font-weight: $font-weight-bold;

  &.btn-primary {
    background-color: $yellow;
    border-color: $yellow;
    color: white;
    padding-left: $btn-padding-x * 2;
    padding-right: $btn-padding-x * 4.5;
    clip-path: polygon(14px 0%, 100% 0%, calc(100% - 14px) 100%, 0% 100%);
    position: relative;

    &:focus,
    &.focus {
      box-shadow: none;
    }

    &:hover {
      background-color: darken($yellow, 3%);
      border-color: darken($yellow, 3%);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: darken($yellow, 7%);
      border-color: darken($yellow, 7%);
    }

    // Lines
    &::after {
      content: "";
      position: absolute;
      right: -$btn-border-width;
      top: -$btn-border-width;
      bottom: 0;
      //background: url('/assets/lines.svg') no-repeat;
      background: url('https://weser-diemel-beton.de/typo3conf/ext/hotsushi_configuration/Resources/Public/Images/lines.svg') no-repeat;
      background-size: contain;
      background-position: right;
      height: calc(100% + #{$btn-border-width * 2});
      width: 100%;
      display: block;
    }
  }
}

.hamburger {
  width: 35px;
  display: flex;
  flex-direction: column;
  padding: 0;
  clip-path: none;
  align-items: normal;

  span {
    height: 4px;
    width: 100%;
    margin: 0;
    display: block;
    background-color: $primary;
    border-radius: 2px;
    transition: all 0.2s $easing-custom;

    &:nth-child(2) {
      margin: 5px 0;
      background-color: $darkblue;
    }
    &:nth-child(3) {
      background-color: $green;
    }
  }

  &.active {
    span {
      transform: translate3d(0, 8px, 0) rotate(45deg);
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translate3d(0, -10px, 0) rotate(-45deg);
      }
    }
  }
}
