.card {
  border:none;
  box-shadow: 0 4px 14px rgba(black, 0.11);
}

.card-header {
  background: $darkblue;
  color: white;
  font-weight: $font-weight-bold;
  @include font-size(22px);
  padding: 0.35rem 1rem;
  position: relative;

  &::after {
    @include pseudo-content;
    background: rgba(white, 0.08);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
}

.card-body {
  b {
    color: $lightgreen;
    @include font-size(18px);
    margin-bottom: 6px;
    display: block;
  }
  p {
    margin-bottom: 0;
  }

  a {
    color: inherit;
  }
}