img {
  max-width: 100%;
  height: auto;
}

.container-max-width {
  max-width: $container-max-width;
  @include media-breakpoint-up(md) {
    padding-right: ($grid-gutter-width * 1.2);
    padding-left: ($grid-gutter-width * 1.2);
  }
  @include media-breakpoint-up(xl) {
    padding-right: ($grid-gutter-width * 1.5);
    padding-left: ($grid-gutter-width * 1.5);
  }
}

#more1, #more2 {
  display: none;
}

%clearfix {
  *zoom: 1;

  &::after,
  &::before {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
