#map-canvas {
  height: 0px;
  overflow: hidden;
  padding-top: 5px / 4px * 100%;

  @include media-breakpoint-up(md) {
    padding-top: 6px / 16px * 100%;
  }
}

// Info Window
#gm-info-window {
  padding: 0.5rem;
  font-size: 1rem;
  font-family: $font-family-sans-serif;
  font-weight: normal;
  color: $body-color;
  h5 {
    margin-bottom: 7px;
  }
}